<template>
  <v-container class="pa-0 full-width full-height">
    <v-responsive aspect-ratio="16/9">
      <video autoplay muted loop class="video" disablePictureInPicture>
        <source
          src="https://firebasestorage.googleapis.com/v0/b/web-hodl.appspot.com/o/Globe.mp4?alt=media&token=ea7a6d48-ab84-40b8-b5ba-9f9187614376"
          type="video/mp4"
        />
      </video>
    </v-responsive>
    <v-row justify="center">
      <v-alert
        v-if="showSuccessAlert || showErrorAlert"
        :type="showSuccessAlert ? 'success' : 'error'"
        dismissible
        @input="resetAlerts"
      >
        {{ alertMessage }}
      </v-alert>
    </v-row>

    <transition name="slide">
      <div v-show="showForm">
        <v-row justify="center" class="mt-12">
          <a href="https://hodlotc.com/" target="_blank">
            <v-img src="../assets/images/Logo.png" contain height="80"></v-img>
          </a>
        </v-row>
        <v-row justify="center">
          <v-card class="card transparent-card pb-0" width="600px">
            <h1 class="white-text text-center">LOGIN</h1>
            <h5 class="white-text text-center">
              Please make sure you are visiting https://dashboard.hodlotc.com
            </h5>
            <v-form ref="form">
              <v-row justify="center">
                <div class="mt-2 mx-80">
                  <v-text-field
                    required
                    prepend-icon="mdi-email-box"
                    placeholder="Email:"
                    dark
                    v-model="formattedEmail"
                    @input="sanitizeEmailInput"
                    rounded
                    class="mt-4 px-4 pt-0 textfield auto"
                    :append-outer-icon="
                      hasStartedTyping.email && getErrorMessages('email').length
                        ? 'mdi-alert-circle'
                        : ''
                    "
                  >
                    <template v-slot:append-outer>
                      <v-tooltip
                        v-if="
                          hasStartedTyping.email &&
                          getErrorMessages('email').length
                        "
                        right
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="red">
                            mdi-alert-circle
                          </v-icon>
                        </template>
                        <span>{{ getErrorMessages('email').join(', ') }}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                  <v-text-field
                    required
                    prepend-icon="mdi-lock"
                    placeholder="Password:"
                    dark
                    v-model="password"
                    :type="showPassword ? 'text' : 'password'"
                    rounded
                    class="mt-8 px-4 pt-0 textfield auto"
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="showPassword = !showPassword"
                    :append-outer-icon="
                      hasStartedTyping.password &&
                      getErrorMessages('password').length
                        ? 'mdi-alert-circle'
                        : ''
                    "
                  >
                    <template v-slot:append-outer>
                      <v-tooltip
                        v-if="
                          hasStartedTyping.password &&
                          getErrorMessages('password').length
                        "
                        right
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="red">
                            mdi-alert-circle
                          </v-icon>
                        </template>
                        <span>{{ getErrorMessages('password').join(', ') }}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                  <v-row>
                    <v-col class="d-flex justify-center align-center nowrap-col">
                      <v-btn
                        class="mt-8 nowrap-text"
                        :style="{ color: '#3C96FE' }"
                        @click="$router.push('/forgotpassword')"
                        text
                      >
                        Forgot Password?
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-row>
            </v-form>
            <v-row justify="center">
              <v-btn dark rounded class="pink-btn my-5" @click="handleLogin">
                Login
              </v-btn>
            </v-row>
            <v-row justify="center">
              <v-btn
                dark
                rounded
                class="mb-4 transparent-bg btn"
                @click="$router.push({ path: '/register' })"
              >
                Register
              </v-btn>
            </v-row>
          </v-card>
        </v-row>
        <v-row justify="center">
          <v-overlay :value="loading" style="z-index: 999">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-overlay>
        </v-row>
      </div>
    </transition>
    <VerificationDialog
      :showDialog="showVerificationDialog"
      @resend-email="resendVerificationEmail"
    />
  </v-container>
</template>

<script>
import VerificationDialog from "@/components/VerificationDialog.vue";
import axios from "axios";
import { auth } from "../firebaseConfig.js";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import {
  collection,
  getDocs,
  where,
  getFirestore,
  updateDoc,
  arrayUnion,
  query,
  setDoc,
  getDoc,
  doc,
} from "firebase/firestore";
import { firebaseApp } from "../firebaseConfig";

const db = getFirestore(firebaseApp);

export default {
  name: "Login",
  components: {
    VerificationDialog,
  },
  data: () => ({
    uid: null,
    userCollection: null,
    rawEmail: "",
    showVerificationDialog: false,
    clientSN: null,
    showForm: false,
    showResendVerificationButton: false,
    loading: false,
    showPassword: false,
    showSuccessAlert: false,
    showErrorAlert: false,
    alertMessage: "",
    password: "",
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+\..+/.test(v) || "Email must be valid",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => v.length >= 6 || "Password must be at least 6 characters",
      (v) => /[A-Z]/.test(v) || "Must contain at least one uppercase letter",
      (v) => /[a-z]/.test(v) || "Must contain at least one lowercase letter",
      (v) => /[0-9]/.test(v) || "Must contain at least one number",
      (v) =>
        /[!@#\$%\^&*]/.test(v) ||
        "Must contain at least one special character",
    ],
    hasStartedTyping: {
      email: false,
      password: false,
    },
  }),
  created() {
    this.showForm = true;
    const user = auth.currentUser;
    if (user && localStorage.getItem("user")) {
      this.$router.push("/account");
    }
  },
  computed: {
    formattedEmail: {
      get() {
        return this.rawEmail;
      },
      set(value) {
        this.rawEmail = value.toLowerCase();
      },
    },
  },
  watch: {
    rawEmail() {
      if (!this.hasStartedTyping.email) {
        this.hasStartedTyping.email = true;
      }
    },
    password() {
      if (!this.hasStartedTyping.password) {
        this.hasStartedTyping.password = true;
      }
    },
  },
  methods: {
    getErrorMessages(field) {
      switch (field) {
        case "email":
          return this.emailRules
            .map((rule) =>
              rule(this.formattedEmail) === true
                ? null
                : rule(this.formattedEmail)
            )
            .filter((message) => message !== null);
        case "password":
          return this.passwordRules
            .map((rule) =>
              rule(this.password) === true ? null : rule(this.password)
            )
            .filter((message) => message !== null);
        default:
          return [];
      }
    },
    resetAlerts() {
      this.showSuccessAlert = false;
      this.showErrorAlert = false;
    },
    sanitizeEmailInput() {
      this.rawEmail = this.rawEmail.replace(/[\s\t]/g, "").toLowerCase();
    },
    async LoginAuth() {
      try {
        this.loading = true;
        const authRes = await signInWithEmailAndPassword(
          auth,
          this.rawEmail,
          this.password
        );
        this.loading = false;
        return authRes;
      } catch (error) {
        this.loading = false;
        let errorMessage = "Something went wrong, please try again!";
        switch (error.code) {
          case "auth/invalid-email":
            errorMessage =
              "The email address is not valid. Please check your email.";
            break;
          case "auth/user-disabled":
            errorMessage =
              "Your account has been disabled. Please contact support.";
            break;
          case "auth/user-not-found":
            errorMessage =
              "No account found with this email. Please register or try again.";
            break;
          case "auth/wrong-password":
            errorMessage = "The password is incorrect. Please try again.";
            break;
          case "auth/invalid-login-credentials":
            errorMessage =
              "Password or email is incorrect, Please try again.";
            break;
          case "auth/too-many-requests":
            errorMessage =
              "Too many failed login attempts. Please try again later.";
            break;
          case "auth/network-request-failed":
            errorMessage =
              "Network error. Please check your internet connection.";
            break;
          default:
            errorMessage = error.message || errorMessage;
        }
        this.alertMessage = errorMessage;
        this.showSuccessAlert = false;
        this.showErrorAlert = true;
        return null;
      }
    },
    async fetchUserUID(email) {
      const collectionsToSearch = ["individuals", "companies", "trusts"];
      try {
        for (const collectionName of collectionsToSearch) {
          const collectionRef = collection(db, collectionName);
          const q = query(collectionRef, where("email", "==", email));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const userData = querySnapshot.docs[0].data();
            return userData.uid;
          }
        }
        return null;
      } catch (error) {
        return null;
      }
    },
    async resendVerificationEmail() {
      this.loading = true;
      try {
        const uid = await this.fetchUserUID(this.rawEmail);
        if (uid) {
          await this.sendVerificationEmail(uid, this.rawEmail);
          this.alertMessage = "Verification email resent successfully!";
          this.showResendVerificationButton = false;
          this.showSuccessAlert = true;
          this.showErrorAlert = false;
        } else {
          throw new Error("User not found");
        }
      } catch (error) {
        console.error("Failed to resend verification email:", error);
        this.alertMessage =
          "Please enter your email address in the email field and try again.";
        this.showErrorAlert = true;
      } finally {
        this.loading = false;
      }
    },
    async sendVerificationEmail(uid, email) {
      const verificationLink = `https://dashboard.hodlotc.com/verifyaccount?uid=${uid}`;
      const payload = {
        toEmail: email,
        subject: "Verify Your Account",
        link: verificationLink,
        otp: "",
      };
      try {
        const response = await fetch(
          "https://hodl-vm.northeurope.cloudapp.azure.com/api/EmailApi/send",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Verification email sent successfully:", data);
        this.$toast.success("Verification email sent successfully!");
      } catch (error) {
        console.error("Failed to send verification email:", error);
        this.$toast.error("Failed to send verification email.");
      }
    },
    executeRecaptcha() {
      grecaptcha.ready(() => {
        grecaptcha
          .execute("6LeDERMqAAAAAIr-uwYKwM1ai1I25jZtVFwh-JbT", {
            action: "submit",
          })
          .then((token) => {
            console.log("reCAPTCHA token:", token);
          });
      });
    },
    async fetchOvexEmailUsingEmail() {
      this.loading = true;
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          this.rawEmail,
          this.password
        );
        const uid = userCredential.user.uid;
        if (!uid) {
          throw new Error("User not found");
        }
        const collectionsToSearch = ["individuals", "companies", "trusts"];
        for (const collectionName of collectionsToSearch) {
          const docRef = doc(db, collectionName, uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists() && docSnap.data().OvexEmail) {
            let OvexEmail = docSnap.data().OvexEmail;
            const lowercaseOvexEmail = OvexEmail.toLowerCase();
            if (OvexEmail !== lowercaseOvexEmail) {
              await updateDoc(docRef, { OvexEmail: lowercaseOvexEmail });
            }
            this.OvexEmail = lowercaseOvexEmail;
            this.userCollection = collectionName;
            this.uid = uid;
            this.fetchAndFilterClientsByEmail(this.OvexEmail);
            break;
          }
        }
      } catch (error) {
        console.error("Failed to fetch OvexEmail:", error);
        this.showErrorAlert = true;
        this.alertMessage = "User does not exist.";
      } finally {
        this.loading = false;
      }
    },
    async fetchAndFilterClientsByEmail(OvexEmail) {
      try {
        const response = await axios.get(
          "https://hodl-vm.northeurope.cloudapp.azure.com/api/GetClients"
        );
        const data = await response.data;
        const filteredClients = data.filter(
          (client) => client.email === OvexEmail
        );
        if (filteredClients.length > 0) {
          this.clientSN = filteredClients[0].sn;
          if (this.userCollection && this.uid) {
            const userDocRef = doc(db, this.userCollection, this.uid);
            await updateDoc(userDocRef, { clientSN: this.clientSN });
          } else {
            console.error(
              "User collection or UID not found. Cannot update Firestore document."
            );
          }
        } else {
          console.log("No matching client found for email:", OvexEmail);
        }
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    },
    async login() {
      const formValid = this.$refs.form.validate();
      if (!formValid) {
        this.alertMessage = "Please fill in the required fields!";
        this.showSuccessAlert = false;
        this.showErrorAlert = true;
        return false;
      }
      try {
        await setPersistence(auth, browserLocalPersistence);
        const authRes = await this.LoginAuth();
        if (!authRes) {
          // Specific error already handled in LoginAuth
          return false;
        }
        if (!authRes.user.emailVerified) {
          this.showVerificationDialog = true;
          this.showResendVerificationButton = true;
          this.showSuccessAlert = false;
          await auth.signOut();
          return false;
        }
        const loginTime = new Date().toISOString();
        const ipResponse = await axios.get("https://api.ipify.org?format=json");
        const ipAddress = ipResponse.data.ip;
        const userLoginsDocRef = doc(db, "logins", authRes.user.uid);
        const docSnapshot = await getDoc(userLoginsDocRef);
        if (docSnapshot.exists()) {
          await updateDoc(userLoginsDocRef, {
            logins: arrayUnion({ ip: ipAddress, loginTime: loginTime }),
          });
        } else {
          await setDoc(userLoginsDocRef, {
            logins: [{ ip: ipAddress, loginTime: loginTime }],
          });
        }
        const userQuery = query(
          collection(db, "individuals"),
          where("email", "==", this.rawEmail)
        );
        const individualSnapshot = await getDocs(userQuery);
        if (individualSnapshot.docs.length > 0) {
          individualSnapshot.forEach((doc) => {
            const currentUser = { ...doc.data(), id: doc.id };
            localStorage.setItem("email", this.rawEmail);
            localStorage.setItem("user", JSON.stringify(currentUser));
            if (currentUser.is2FAEnabled) {
              this.$router.push({
                name: "TwoFAModal",
                query: { userEmail: this.rawEmail },
              });
            } else {
              this.$router.push("/account");
            }
            this.alertMessage = "Logged in successfully!";
            this.showSuccessAlert = true;
            this.showErrorAlert = false;
          });
        } else {
          let companyQuery = query(
            collection(db, "companies"),
            where("email", "==", this.rawEmail)
          );
          let companySnapshot = await getDocs(companyQuery);
          if (companySnapshot.docs.length > 0) {
            companySnapshot.forEach((doc) => {
              const currentUser = { ...doc.data(), id: doc.id };
              localStorage.setItem("email", this.rawEmail);
              localStorage.setItem("user", JSON.stringify(currentUser));
              if (currentUser.is2FAEnabled) {
                this.$router.push({
                  name: "TwoFAModal",
                  query: { userEmail: this.rawEmail },
                });
              } else {
                this.$router.push("/account");
              }
              this.alertMessage = "Logged in successfully!";
              this.showSuccessAlert = true;
              this.showErrorAlert = false;
            });
          } else {
            let trustQuery = query(
              collection(db, "trusts"),
              where("email", "==", this.rawEmail)
            );
            this.loading = true;
            let trustSnapshot = await getDocs(trustQuery);
            this.loading = false;
            if (trustSnapshot.docs.length > 0) {
              trustSnapshot.forEach((doc) => {
                const currentUser = { ...doc.data(), id: doc.id };
                localStorage.setItem("email", this.rawEmail);
                localStorage.setItem("user", JSON.stringify(currentUser));
                if (currentUser.is2FAEnabled) {
                  this.$router.push({
                    name: "TwoFAModal",
                    query: { userEmail: this.rawEmail },
                  });
                } else {
                  this.$router.push("/account");
                }
                this.alertMessage = "Logged in successfully!";
                this.showSuccessAlert = true;
                this.showErrorAlert = false;
              });
            } else {
              this.alertMessage =
                "Email or Password is incorrect, please try again!";
              this.showSuccessAlert = false;
              this.showErrorAlert = true;
              await auth.signOut();
              return false;
            }
          }
        }
        return true;
      } catch (error) {
        console.error(error);
        this.alertMessage = "Something went wrong, please try again!";
        this.showSuccessAlert = false;
        this.showErrorAlert = true;
        return false;
      } finally {
        this.loading = false;
      }
    },
    async handleLogin() {
      const success = await this.login();
      if (success) {
        await this.fetchOvexEmailUsingEmail();
        this.executeRecaptcha();
      }
    },
  },
};
</script>

<style scoped>
.logo {
  margin-top: 5rem;
  height: 80px;
}

.nowrap-col {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nowrap-text {
  white-space: nowrap;
}

.card {
  margin-top: 2rem;
  max-width: 80%;
  padding: 1rem;
}

.textfield {
  width: 350px;
  height: 30px;
  background-color: #f8f8f82b;
  font-size: 14px;
}

.video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  pointer-events: none;
  user-select: none;
}

h1 {
  font-size: 26px;
}

/* Animations: */
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.6s;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
