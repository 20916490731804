var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 full-width full-height"},[_c('v-responsive',{attrs:{"aspect-ratio":"16/9"}},[_c('video',{staticClass:"video",attrs:{"autoplay":"","muted":"","loop":"","disablePictureInPicture":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"https://firebasestorage.googleapis.com/v0/b/web-hodl.appspot.com/o/Globe.mp4?alt=media&token=ea7a6d48-ab84-40b8-b5ba-9f9187614376","type":"video/mp4"}})])]),_c('v-row',{attrs:{"justify":"center"}},[(_vm.showSuccessAlert || _vm.showErrorAlert)?_c('v-alert',{attrs:{"type":_vm.showSuccessAlert ? 'success' : 'error',"dismissible":""},on:{"input":_vm.resetAlerts}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")]):_vm._e()],1),_c('transition',{attrs:{"name":"slide"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showForm),expression:"showForm"}]},[_c('v-row',{staticClass:"mt-12",attrs:{"justify":"center"}},[_c('a',{attrs:{"href":"https://hodlotc.com/","target":"_blank"}},[_c('v-img',{attrs:{"src":require("../assets/images/Logo.png"),"contain":"","height":"80"}})],1)]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-card',{staticClass:"card transparent-card pb-0",attrs:{"width":"600px"}},[_c('h1',{staticClass:"white-text text-center"},[_vm._v("LOGIN")]),_c('h5',{staticClass:"white-text text-center"},[_vm._v(" Please make sure you are visiting https://dashboard.hodlotc.com ")]),_c('v-form',{ref:"form"},[_c('v-row',{attrs:{"justify":"center"}},[_c('div',{staticClass:"mt-2 mx-80"},[_c('v-text-field',{staticClass:"mt-4 px-4 pt-0 textfield auto",attrs:{"required":"","prepend-icon":"mdi-email-box","placeholder":"Email:","dark":"","rounded":"","append-outer-icon":_vm.hasStartedTyping.email && _vm.getErrorMessages('email').length
                      ? 'mdi-alert-circle'
                      : ''},on:{"input":_vm.sanitizeEmailInput},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [(
                        _vm.hasStartedTyping.email &&
                        _vm.getErrorMessages('email').length
                      )?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle ")])]}}],null,false,3358496843)},[_c('span',[_vm._v(_vm._s(_vm.getErrorMessages('email').join(', ')))])]):_vm._e()]},proxy:true}]),model:{value:(_vm.formattedEmail),callback:function ($$v) {_vm.formattedEmail=$$v},expression:"formattedEmail"}}),_c('v-text-field',{staticClass:"mt-8 px-4 pt-0 textfield auto",attrs:{"required":"","prepend-icon":"mdi-lock","placeholder":"Password:","dark":"","type":_vm.showPassword ? 'text' : 'password',"rounded":"","append-icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye',"append-outer-icon":_vm.hasStartedTyping.password &&
                    _vm.getErrorMessages('password').length
                      ? 'mdi-alert-circle'
                      : ''},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [(
                        _vm.hasStartedTyping.password &&
                        _vm.getErrorMessages('password').length
                      )?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle ")])]}}],null,false,3358496843)},[_c('span',[_vm._v(_vm._s(_vm.getErrorMessages('password').join(', ')))])]):_vm._e()]},proxy:true}]),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center align-center nowrap-col"},[_c('v-btn',{staticClass:"mt-8 nowrap-text",style:({ color: '#3C96FE' }),attrs:{"text":""},on:{"click":function($event){return _vm.$router.push('/forgotpassword')}}},[_vm._v(" Forgot Password? ")])],1)],1)],1)])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"pink-btn my-5",attrs:{"dark":"","rounded":""},on:{"click":_vm.handleLogin}},[_vm._v(" Login ")])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mb-4 transparent-bg btn",attrs:{"dark":"","rounded":""},on:{"click":function($event){return _vm.$router.push({ path: '/register' })}}},[_vm._v(" Register ")])],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-overlay',{staticStyle:{"z-index":"999"},attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1)],1)],1)]),_c('VerificationDialog',{attrs:{"showDialog":_vm.showVerificationDialog},on:{"resend-email":_vm.resendVerificationEmail}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }